<template>
  <div>
    <label
      class="field__label"
      :class="subject.required === true ? 'field--required' : null"
      :id="subject.id"
    >
      {{ subject.title }}
    </label>
    <!-- 欄位內容 -->
    <slot></slot>
    <!-- 欄位內容 -->
    <small v-if="subject.memo" class="field__desc">
      {{ subject.memo }}
    </small>
    <div
      v-if="validateError"
      class="error_msg"
    >
      輸入的資料有誤或未填寫，請確認
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subject: {
      type: Object,
    },
    validateError: {
      type: Boolean,
    },
  }
}
</script>

<style scoped>
.error_msg {
  color: #fe0000;
  font-size: 12px;
  margin-top: 8px;
}
</style>
