<template>
  <div class="carousel-selection" :class="{ 'carousel-selection--has-error': hasError }">
    <div class="carousel-selection-field" @click="handleFieldIsClicked">
      {{ textForField }}
    </div>

    <b-modal ref="carousel" centered hide-header hide-footer>
      <template #default="{ close }">
        <div class="carousel-selection-modal" :style="colorObject">
          <button class="carousel-selection-modal__close" @click="close()">
            <img src="@/assets/images/close.svg" />
          </button>

          <swiper ref="swiper" :options="swiperOption" @click.native="sliderClicked">
            <swiper-slide v-for="(candidate, idx) in candidates" :key="`${renderCount}-${idx}`">
              <div class="badge__detail__hero">
                <div class="badge__detail__hero__img">
                  <img
                    v-show="candidate.loaded"
                    :src="candidate.image"
                    @load="candidate.loaded = true"
                    class="img-fluid"
                  />

                  <div v-if="candidate.image && !candidate.loaded" class="text-center p-4">
                    <b-spinner variant="secondary"></b-spinner>
                  </div>
                </div>
              </div>
              <div class="py-3 border-bottom px-4">
                <div class="badge__detail__hero__title mt-2">{{ candidate.title }}</div>
                <div v-html="candidate.description"></div>
              </div>
              <div class="py-3">
                <SharedButton class="s-btn-bg-primary carousel-selection__select-button">選擇</SharedButton>
              </div>
            </swiper-slide>
          </swiper>

          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
import themeColor from "@/mixins/liff/themeColor";
import deepGet from "lodash/get";
import isEqual from "lodash/isEqual";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  mixins: [themeColor],
  components: {
    SharedButton,
    Swiper,
    SwiperSlide,
  },
  props: {
    // value: [String, Number, Array, Object],
    value: {
      type: Array,
      default: () => {[]},
    },
    placeholder: {
      type: String,
      required: false,
    },
    candidateFetcher: { // format: { title, description, image, value, placeholderWhenSelected }
      type: Function,
      required: true,
    },
    valueMatcher: {
      type: Function,
      default: () => {
        return (candidate, incomingValue) => isEqual(candidate.value, incomingValue)
      },
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      candidates: [],
      selectedCandidate: [],
      swiperOption: {
        loop: true,
        noSwipingSelector: 'button',
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      renderCount: 0,
    }
  },
  computed: {
    textForField() {
      return deepGet(this.selectedCandidate, 'placeholderWhenSelected') || this.placeholder || '請選擇'
    },
  },
  watch: {
    value(val) {
      this.updateSelectedCandidate(val[0])
    },
  },
  async mounted() {
    await this.fetchCandidates()
  },
  methods: {
    sliderClicked (event) { // NOTE: loop 模式時，swiper 會 copy 第一張與最後一張 slide 的 DOM 元素，但是 event listeners 會遺失，因此改將事件綁在更外層的 swiper element
      if (event.target.classList.contains('carousel-selection__select-button')) {
        const candidate = this.candidates[this.$refs.swiper.swiperInstance.realIndex];
        this.handleCandidateIsSelected(candidate)
        this.$refs.carousel.hide()
      }
    },
    async fetchCandidates() {
      this.$set(this, 'candidates', (await this.candidateFetcher()).map(candidate => ({ ...candidate, loaded: false })))
      this.updateSelectedCandidate(this.value)
      this.renderCount++
    },
    updateSelectedCandidate(incomingValue) {
      this.selectedCandidate = this.candidates.find(candidate => this.valueMatcher(candidate, incomingValue))
    },
    handleFieldIsClicked() {
      this.$refs.carousel.show()
      this.$nextTick(() => {
        if (this.selectedCandidate) {
          this.$refs.swiper.swiperInstance.slideTo(this.candidates.indexOf(this.selectedCandidate) + 1) // NOTE: loop 模式時 slideTo() function 不精準，需要將 index + 1 才是正確的位置
        }
      })
    },
    handleCandidateIsSelected(candidate) {
      this.selectedCandidate = candidate

      this.$emit('input', [candidate.value])
    },
  }
}
</script>

<style lang="scss" scoped>
.carousel-selection-field {
  width: 100%;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: inherit;
  font-size: 18px;
  background-color: white;
  color: var(--liff-primary_text_color);
  cursor: pointer;

  &:focus {
    border-color: var(--liff-primary_text_color);
  }
}

.carousel-selection--has-error {
  .carousel-selection-field {
    border-color: #fe0000;
  }
}

.carousel-selection-modal {
  &__close {
    position: absolute;
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    border-radius: 100%;
    aspect-ratio: 1;
    padding: 10px;
    line-height: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 0 8px;
    background: white;
    border-radius: 40px;
    position: absolute;
    bottom: 12px;
    z-index: 1;
    aspect-ratio: 1;
    width: 40px;
    height: 40px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);

    &::after {
      color: black;
      font-size: 18px;
    }
  }

  .swiper-button-prev {
    left: -5px;
  }
  .swiper-button-next {
    right: -5px;
  }
}

::v-deep .modal-content {
  border-radius: 10px;
  font-size: 15px;
}

::v-deep .carousel-selection__select-button {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  font-size: var(--s-text-xl);
  font-weight: var(--s-weight-medium);
  line-height: var(--s-text-xl-line-height);
}
</style>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
