<template>
  <div>
    <Loading
      v-if="isLoading"
      loading-message="載入中..."
      :background-color="'#ffffff'"
    />

    <img
      class="banner-image w-100"
      v-if="deepGet(form, 'config.content_page.banner_image')"
      :src="deepGet(form, 'config.content_page.banner_image')"
    />

    <div class="page">
      <div
        v-if="deepGet(form, 'config.content_page.form_title')"
        class="page__title"
      >
        {{ deepGet(form, 'config.content_page.form_title') }}
      </div>
      <div v-if="deepGet(form, 'config.content_page.form_desc')" class="page__desc">
        {{ deepGet(form, 'config.content_page.form_desc') }}
      </div>
      <div
        v-if="deepGet(form, 'config.content_page.content')"
        v-html="deepGet(form, 'config.content_page.content')"
        class="page__content"
      ></div>

      <DynamicForm
        ref="dynamicForm"
        class="my-2"
        :input-subjects="subjectsWithoutSection"
        v-model="inputForm"
        :current-page="currentPageNumber"
      ></DynamicForm>

      <b-card header-tag="header" class="mb-3 subject-group" v-for="(section, i) in sectionsOfCurrentPage" :key="`section${i}page${currentPageNumber}`">
        <template #header>
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="mb-0">{{ deepGet(section, 'title') }}</h6>
          </div>
        </template>
        <DynamicForm
          ref="dynamicFormForSection"
          :input-subjects="subjectsOfCurrentSection(section)"
          v-model="inputForm"
        ></DynamicForm>
      </b-card>

      <div class="step-indicator my-3" v-if="pages.length > 0">
        <div
          v-for="index in (pages.length + 1)"
          :key="index"
          class="step-indicator__step"
          :class="{ 'step-indicator__step--filled': index <= currentPageNumber + 1, 'step-indicator__step--current': index === currentPageNumber + 1 }"
        ></div>
      </div>

      <div class="d-flex s-space-x-2" v-if="pages.length > 0">
        <template v-if="currentPageNumber === 0">
          <SharedButton class="s-btn-bg-primary" @click="currentPageNumber++">
            下一步
          </SharedButton>
        </template>
        <template v-else-if="currentPageNumber === pages.length">
          <SharedButton class="s-btn-outline-primary" @click="currentPageNumber--">
            上一步
          </SharedButton>
          <SharedButton class="s-btn-bg-primary" @click="submitForm">
            {{ deepGet(form, 'config.content_page.button_text', '送出') }}
          </SharedButton>
        </template>
        <template v-else>
          <SharedButton class="s-btn-outline-primary" @click="currentPageNumber--">
            上一步
          </SharedButton>
          <SharedButton class="s-btn-bg-primary" @click="currentPageNumber++">
            下一步
          </SharedButton>
        </template>
      </div>
      <template v-else>
        <SharedButton class="s-btn-bg-primary" @click="submitForm">
          {{ deepGet(form, 'config.content_page.button_text', '送出') }}
        </SharedButton>
      </template>

      <div
        v-if="deepGet(form, 'terms')"
        v-html="deepGet(form, 'terms')"
        class="mt-3 p-3"
      ></div>
    </div>
  </div>
</template>

<script>
import {
  isAfter,
  set,
  getHours,
  getMinutes,
} from "date-fns";
import deepGet from "lodash/get";
import sortBy from "lodash/sortBy";
import { mapState } from "vuex";
import formApi from "@/apis/liff/v2/form";
import DynamicForm from "@/components/DynamicForm/DynamicForm.vue";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import Loading from "@/components/Page/Liff/Shared/Loading";
import { parseFormSubjects } from "@/utils/DynamicFormUtils";

export default {
  components: {
    DynamicForm,
    SharedButton,
    Loading,
  },
  computed: {
    ...mapState({
      user: (state) => state.liffAuth.user,
    }),
    hasStarted() {
      const today = set(new Date(), {
        hours: String(getHours(new Date())).padStart(2, "0"),
        minutes: String(getMinutes(new Date())).padStart(2, "0"),
      });

      const startDate = set(new Date(this.form.start_at), {
        hours: String(getHours(new Date(this.form.start_at))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(this.form.start_at))).padStart(2, "0"),
      });

      return isAfter(today, startDate);
    },
    hasEnded() {
      const today = set(new Date(), {
        hours: String(getHours(new Date())).padStart(2, "0"),
        minutes: String(getMinutes(new Date())).padStart(2, "0"),
      });

      const endDate = set(new Date(this.form.end_at), {
        hours: String(getHours(new Date(this.form.end_at))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(this.form.end_at))).padStart(2, "0"),
      });

      return isAfter(today, endDate);
    },
    isInValidTimeRange() {
      return this.hasStarted && false == this.hasEnded;
    },
    sections() {
      return deepGet(this.form, 'config.sections', []);
    },
    pages() {
      return deepGet(this.form, 'config.pages', []).filter(this.areaIsVisible);
    },
    subjectsWithoutSection() {
      return this.subjects.filter(subject => {
        return subject.section_id === undefined && subject.page_id === this.pageIdOfCurrentPage && this.areaIsVisible(subject);
      });
    },
    sectionsOfCurrentPage() {
      if (this.currentPageNumber === 0) {
        return this.sections.filter(section => section.page_id === undefined && this.areaIsVisible(section));
      }

      return this.sections.filter(section => section.page_id === this.pageIdOfCurrentPage && this.areaIsVisible(section));
    },
    pageIdOfCurrentPage() {
      const sortedPages = sortBy(this.pages, 'order');
      return deepGet(sortedPages[this.currentPageNumber - 1], '_id', undefined);
    },
  },
  data() {
    return {
      inputForm: {},
      subjects: [],
      form: {},
      isLoading: true,
      startedWriting: false,
      currentPageNumber: 0,
    };
  },
  watch: {
    inputForm: {
      handler: function (val) {
        console.log("form", val);
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.fetchForm();

    if (false == this.isInValidTimeRange || this.form.status !== 'published') {
      this.$router.replace({ name: "LiffFormIndex", params: { form_id: this.form.id } });
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    deepGet,
    areaIsVisible(subjectOrSectionOrPage) {
      const anyConditions = deepGet(subjectOrSectionOrPage, 'visible_when_any', []);
      if (anyConditions.length === 0) {
        return true;
      }

      return anyConditions.some(condition => this.subjects.some(possibleSubject => {
        return condition.field_id === possibleSubject._id
          && condition.value === this.inputForm[possibleSubject.id];
      }));
    },
    subjectsOfCurrentSection(section) {
      return this.subjects.filter(subject => subject.section_id === section._id && this.areaIsVisible(subject));
    },
    async fetchForm() {
      try {
        const response = await formApi.getForm(this.$route.params.form_id, {});
        this.form = response.data.data;
        const checkRegisteredPemission =
          this.form.config.permissions.includes("registered");
        if (checkRegisteredPemission && !this.user.registered_at) {
          this.$router.push({ name: "LiffRegisterIndex" });
        } else {
          this.subjects = parseFormSubjects(this.form);
        }
      } catch (error) {
        if (error.response.status === 404) {
          this.$swal("錯誤", "找不到此問券", "error");
        } else {
          console.error(error);
          this.$swal("錯誤", "", "error");
        }
      }
    },
    async submitForm() {
      const validate = await this.$refs.dynamicForm.validate();

      if (!validate) return;

      try {
        this.isLoading = true;

        let answers = [];

        for (let i = 0; i < this.form.subjects.length; i++) {
          const subject = this.form.subjects[i];
          let answer = {
            subject_id: subject.id,
            value: this.inputForm[subject.id] ?? null,
          };
          answers.push(answer);
        }

        await formApi.saveResult(this.form.id, { answers });

        this.$router.push({ name: "LiffFormThanks" });
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "送出問券時發生問題，請稍候再試", "error");
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  margin-top: 12px;
  margin-bottom: 12px;
}

.step-indicator {
  display: flex;
  justify-content: center;

  > * + * {
    margin-left: 12px;
  }

  &__step {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid var(--liff-button-color);

    &--filled {
      background-color: var(--liff-button-color);
    }

    &--current {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid var(--liff-button-color);
        transform: translate(-50%, -50%);
      }
    }
  }
}

.subject-group {
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .card-header {
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #e5e5ea;
    font-size: 16px;
    font-weight: 500;
    padding: 16px 16px 12px 16px;
  }
}
</style>
