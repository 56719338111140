import deepGet from "lodash/get";

class Collection {
  constructor (dataModel) {
    this.collection = dataModel // NOTE: 不與取得資料的方式耦合，因此只要 object 格式吻合 Eloquent model 即可填入
  }

  textForOption (collectionItemId) {
    const mainDisplayFieldIds = this.collection.config.fields
      .filter((field) => deepGet(field, 'config.main_display', false))
      .map(f => deepGet(f, 'config._id'))

    const item = this.collection.items.find(({ id }) => id === collectionItemId)

    let textForOption = item.data[0].value
    if (mainDisplayFieldIds.length > 0) {
      textForOption = item.data
        .filter((field) => mainDisplayFieldIds.includes(field.field_id))
        .map(field => field.value)
        .join(' ')
    }

    return textForOption
  }
}

export default Collection
